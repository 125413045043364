import { UnleashClient } from 'unleash-proxy-client';

let unleashClient = null;
let initPromise = null;

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

async function getIdentity() {
    return new Promise((resolve) => {
        if (window.Identity) {
            resolve(window.Identity);
        } else {
            window.addEventListener('identity-initialized', () => {
                resolve(window.Identity);
            });
        }
    });
}

/**
 * Get the Unleash client
 * @returns {Promise<UnleashClient>}
 */
export async function getUnleashClient() {
    if (unleashClient) {
        return Promise.resolve(unleashClient);
    }

    if (initPromise) {
        return initPromise;
    }

    unleashClient = new UnleashClient({
        url: 'https://unleash-edge.sk8s.vg.no/api/frontend',
        clientKey:
            '*:production.4939e72d3cce41e0464a3e4ef4e64256f222e68113fc92c796fd14df',
        appName: 'frimand',
        disableRefresh: true,
        disableMetrics: true,
    });

    initPromise = new Promise((resolve, reject) => {
        const onReady = () => {
            resolve(unleashClient);
        };

        const onError = (err) => {
            console.error('Unleash error:', err);
            unleashClient = null;
            initPromise = null;
            reject(err);
        };

        unleashClient.on('ready', onReady);
        unleashClient.on('error', onError);
    });

    const identity = await getIdentity();
    const isLoggedIn = await identity.isLoggedIn();

    unleashClient.setContextField('isLoggedIn', isLoggedIn ? '1' : '0');
    unleashClient.setContextField('sessionId', getCookie('_pulse2data'));

    if (isLoggedIn) {
        const userId = await identity.getUserId();
        unleashClient.setContextField('spidId', userId);
    }

    unleashClient.start();

    return initPromise;
}
