export const API_URL = 'https://latest-news-chatbot-bff.prod.sk8s.vg.no/';

/**
 * Tracks user engagement events.
 * @param {string} action
 * @param {Object} object
 * @returns {void}
 */
export function trackEngagement(action, object) {
    const extraId = [object.storyId, object.id].filter(Boolean).join(':');
    const custom = object.custom;

    const payload = {
        type: 'Engagement',
        action,
        object: {
            type: 'UIElement',
            id: `snakkis-chatbot${extraId ? `:${extraId}` : ''}`,
            name: object.name,
            contentId: object.contentId,
        },
    };

    if (custom) {
        payload.object['spt:custom'] = custom;
    }

    window.pulse((sdk) => {
        sdk.track('trackerEvent', payload);
    });
}

/**
 * Fetch wrapper with timeout and default headers.
 * @param {string|URL} url
 * @param {RequestInit} options
 * @param {number} timeout
 * @returns {Promise<Response>}
 */
export function fetchWithTimeout(url, options = {}, timeout = 20000) {
    return fetch(url, {
        signal: AbortSignal.timeout(timeout),
        credentials: 'include',
        ...options,
    });
}

/**
 * Fetch wrapper with retries.
 * @param {string|URL} url
 * @param {RequestInit} options
 * @param {number} retries
 * @param {number} timeout
 * @returns {Promise<Response>}
 */
export async function fetchWithRetry(
    url,
    options,
    retries = 3,
    timeout = 30000,
) {
    const { signal } = options;

    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            const response = await fetchWithTimeout(url, options, timeout);
            return response;
        } catch (error) {
            if (signal?.aborted) {
                throw new Error('Request aborted');
            }
            if (attempt === retries - 1) {
                throw error;
            }
        }
    }
}

/**
 * Delays execution by a given number of milliseconds.
 * @param {number} ms
 * @returns {Promise<void>}
 */
export function delay(ms) {
    return new Promise((res) => setTimeout(res, ms));
}

/**
 * hasDebugDays checks if `debug_days` is present in the main URL.
 * @returns {boolean}
 */
export function hasDebugDays() {
    const mainUrl = new URL(window.location.href);
    return mainUrl.searchParams.has('debug_days');
}

/**
 * Appends `debug_days` parameter to URL if `debug_days` is present in the main URL.
 * @param {URL} url
 * @returns {void}
 */
export function addDebugDays(url) {
    const mainUrl = new URL(window.location.href);
    if (mainUrl.searchParams.has('debug_days')) {
        const debugDays = mainUrl.searchParams.get('debug_days');
        url.searchParams.append('debug_days', debugDays);
    }
}

/**
 * Returns a promise that resolves after the next two animation frames.
 * @returns {Promise<void>}
 */
export function nextFrame() {
    return new Promise((resolve) =>
        requestAnimationFrame(() => requestAnimationFrame(resolve)),
    );
}

/**
 * Returns the last seen timestamp from local storage.
 * @returns {number|null}
 */
export const getLastSeenFromLocalStorage = () => {
    const lastSeen = localStorage.getItem('snakkis.last_seen');
    return lastSeen ? Math.floor(parseInt(lastSeen, 10) / 1000) : null;
};

/**
 * Sets the last seen timestamp in local storage.
 * @returns {void}
 */
export function setLastSeenInLocalStorage() {
    localStorage.setItem('snakkis.last_seen', Date.now().toString());
}

/**
 * Determines whether an update should be fetched, based on the last seen timestamp.
 *
 * The update interval is set to 12 hours.
 **
 * @returns {boolean} True if an update should be fetched, otherwise false.
 */
export const shouldGetUpdate = () => {
    const lastSeen = getLastSeenFromLocalStorage();

    // If there's no record of the last seen time, don't trigger an update
    if (!lastSeen) {
        return false;
    }

    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const elapsedTime = now - lastSeen;

    return elapsedTime >= 3600 * 8;
};
