import { render } from 'preact';
import { getConsent, subscribeToConsent } from '@vgno/utils';
import Snakkis from './Snakkis.jsx';
import { getUnleashClient } from '../../utils/unleash.js';

// We export a function that receives props, including "props.node"
export default async function initSnakkis(props) {
    console.log('Skjedd Siden Sist: Initializing');

    // Ensure we have a valid HTMLElement to mount onto
    const { node } = props;
    if (!node || !(node instanceof HTMLElement)) {
        console.error('No valid node found for Skjedd Siden Sist');
        return;
    }

    const consent = await getConsent('CMP:personalisation');
    let initialized = false;

    if (consent) {
        console.log(
            'Skjedd Siden Sist: Consent already given, checking feature toggle',
        );
        const unleashClient = await getUnleashClient();
        if (unleashClient.isEnabled('skjedd-siden-sist')) {
            console.log('Skjedd Siden Sist: Feature toggle enabled, rendering');
            return render(<Snakkis {...props} />, node);
        }
    } else {
        subscribeToConsent('CMP:personalisation', async (consented) => {
            if (consented && !initialized) {
                initialized = true;
                console.log(
                    'Skjedd Siden Sist: Consent eventually given, checking feature toggle',
                );
                const unleashClient = await getUnleashClient();
                if (unleashClient.isEnabled('skjedd-siden-sist')) {
                    console.log(
                        'Skjedd Siden Sist: Feature toggle enabled, rendering',
                    );
                    return render(<Snakkis {...props} />, node);
                }
            }
        });
    }
}
