/* eslint-disable react/jsx-no-target-blank */
import { ChevronDown, ChevronUp } from '@vgno/icons';
import { useEffect, useState } from 'react';
import { getUnleashClient } from '../../utils/unleash.js';
import './Snakkis.css';
import Story from './Story.jsx';
import Feedback from './Feedback.jsx'
import Preprompts from './Preprompts.jsx';
import {
    addDebugDays,
    hasDebugDays,
    API_URL,
    delay,
    fetchWithTimeout,
    getLastSeenFromLocalStorage,
    setLastSeenInLocalStorage,
    nextFrame,
    trackEngagement,
    shouldGetUpdate,
} from './utils.js';

import { trackImpression, trackLoad } from './tracking.js';

/**
 * @typedef {Object} Message
 * @property {string} uuid - Unique identifier for the message.
 * @property {string} story_name - Name of the story.
 * @property {string} short_summary - A short summary of the story.
 * @property {string[]} bullet_point_summary - Bullet-point summary of the story.
 * @property {string} source - Source identifier for the story.
 */

const Snakkis = ({ node }) => {
    const secondsSinceLastVisit = Math.floor(
        (Date.now() / 1000) - getLastSeenFromLocalStorage()
    );

    const [stories, setStories] = useState([]);
    const [user, setUser] = useState(null);
    const [opened, setOpened] = useState(false);
    const [startStreamingIndex, setStartStreamingIndex] = useState(-1);
    const [title, setTitle] = useState(
        'Dette har skjedd siden sist du var innom:',
    );
    const [lastSeen, setLastSeen] = useState(0);
    const [showPreprompts, setShowPreprompts] = useState(false);

    const TITLES = [
        'Visste du at:', // Arnab
        'Er du oppdatert?', // Nora
        'På VG siden sist:', // Hanna
        'Før du scroller videre: Dette er nytt siden sist!', // Filip
        'Se hva som har skjedd mens du var borte:', // Stian
        'Siden sist: Alt du trenger å vite nå', // Stian
        'Mens du var borte: Det viktigste akkurat nå:', // Stian
        'Se hva som har skjedd siden sist', // Stian
        'Oppdatert på et blunk: Dette har skjedd', // Stian
        'Her er det viktigste du har gått glipp av:', // Nicolai
        '3 saker siden sist:', // Camilla
        'Dette er nytt i Norge og verden:', // Martin
        'Dine viktigste saker:', // Øystein
        'Viktigst for deg nå:', // Øystein
        'Fått med deg dette?', // Karne
        'Oppdater deg her:', // Karne
        'Oppdater deg på det viktigste:', // Johannes
        'Dette har skjedd siden sist du var innom:', // The good old one
    ];

    useEffect(() => {
        async function start() {
            const identity = await getIdentity();
            const isLoggedIn = await identity.isLoggedIn();

            if (isLoggedIn) {
                const user = await identity.getUser();
                console.log('Skjedd Siden Sist: Initializing for user', user);
                initialize(user);
            } else {
                console.log(
                    'Skjedd Siden Sist: Initializing for anonymous user',
                );
                initialize();
            }
        }

        start();
    }, []);

    const initialize = async (user) => {
        const lastSeen = getLastSeenFromLocalStorage();
        setLastSeen(lastSeen);

        // Load just one story for anonymous users, since they only see
        // the beginning of it anyway.
        const numberOfStories = user ? 3 : 1;

        if (hasDebugDays()) {
            console.log(
                `Skjedd Siden Sist: Debug days is set, pretend it's been a while`,
            );
        } else {
            if (!shouldGetUpdate()) {
                console.log(
                    'Skjedd Siden Sist: Less than ~12 hours since last update, aborting',
                );
                setLastSeenInLocalStorage();
                return;
            }
        }

        console.log(
            'Skjedd Siden Sist: More than ~12 hours since last update, fetching new stories',
        );

        let stories;
        try {
            stories = await loadStories({
                topK: numberOfStories,
                timestamp: lastSeen,
            });
        } catch (error) {
            console.error('Skjedd Siden Sist: Error fetching stories', error);
            return;
        }

        console.log('Skjedd Siden Sist: Setting last seen in local storage');
        setLastSeenInLocalStorage();

        const title = getTitle(user?.userId);
        setTitle(title);

        if (stories.length == 0) {
            console.log('Skjedd Siden Sist: No new stories, aborting');
            return;
        }

        const secondsSinceLastVisit = Math.floor(
            (Date.now() / 1000) - lastSeen
        );

        trackLoad(getCustomTrackingData());

        if (user) {
            setUser(user);

            if (userIsInControlGroup(user.userId)) {
                console.log('User is in control group, aborting');
                return;
            }
        }

        trackImpression(node, getCustomTrackingData());

        setStories(stories);
        showComponent();
    };

    const getCustomTrackingData = () => {
        return {
            stories: stories.map((story) => story.story_name),
            secondsSinceLastVisit,
            title,
        }
    };

    const loadStories = async ({ topK, timestamp }) => {
        const url = new URL(API_URL + 'update');

        url.searchParams.append('top_k', topK);
        url.searchParams.append('timestamp', timestamp);

        addDebugDays(url);

        const response = await fetchWithTimeout(url.toString());
        const data = await response.json();

        return data.message;
    };

    const showComponent = async () => {
        node.style.setProperty('max-height', '0px');
        node.classList.add('snakkis-open');
        await delay(250);
        await nextFrame();
        node.style.setProperty('max-height', '200px');
        node.style.setProperty('min-height', '200px');
        node.style.setProperty('overflow', 'hidden');
    };

    const toggleOpen = () => {
        node.style.setProperty('transition', 'max-height 0.35s ease-in-out');
        node.style.setProperty(
            'max-height',
            opened ? '200px' : node.scrollHeight + 'px',
        );
        node.classList.toggle('snakkis-expanded');
        node.addEventListener(
            'transitionend',
            () => {
                node.style.setProperty('max-height', 'none');
            },
            { once: true },
        );

        trackEngagement('Click', {
            storyId: stories[0].uuid,
            contentId: opened ? 'show-less' : 'show-more',
            id: opened ? 'show-less' : 'show-more',
            name: stories[0].story_name,
            custom: getCustomTrackingData()
        });

        setOpened(!opened);
    };

    const shouldGetPreprompts = async () => {
        const unleashClient = await getUnleashClient();
        return unleashClient.isEnabled('skjedd-siden-sist-heivg');
    }

    useEffect(() => {
        async function checkPreprompts() {
            const unleashClient = await getUnleashClient();
            const result = unleashClient.isEnabled('skjedd-siden-sist-heivg');
            setShowPreprompts(result);
        }
        checkPreprompts();
    }, []);

    const getTitle = (userId) => {
        if (userId) {
            if (
                userId % 100 === 8 ||
                userId % 100 === 9 ||
                userId % 100 === 10
            ) {
                const subSegment = Math.floor(userId / 100) % TITLES.length;
                return TITLES[subSegment];
            }
        }
        return 'Dette har skjedd siden sist du var innom:';
    };

    const getShouldAnimate = (userId) => {
        if (userId) {
            if (userId % 100 === 16) {
                const states = [true, false];
                const subSegment = Math.floor(userId / 100) % states.length;
                return states[subSegment];
            }
        }

        return true;
    };

    const userIsInControlGroup = (userId) => {
        // Users whose 4th last digit being 1 are in the control group
        return Math.floor((userId / 1000) % 10) === 1;
    };

    return (
        <section className="custom-style extended snakkis">
            <div className="card">
                <h2 className="snakkis-title">
                    <span className="greeting">
                        {user ? (
                            <>
                                Hei,{' '}
                                <span className="username">
                                    {user.givenName}
                                </span>
                                !
                            </>
                        ) : (
                            <>Hei!</>
                        )}
                    </span>{' '}
                    {title}
                </h2>
                <ul className="story-list-items">
                    {stories.length === 0 && (
                        <li className="story-list-item">
                            <span className="summary-loading" />
                        </li>
                    )}
                    {stories.map((story, i) => (
                        <Story
                            key={story.uuid}
                            storyId={story.uuid}
                            lastSeen={lastSeen}
                            startStreaming={startStreamingIndex === i - 1}
                            finishedStreamingCallback={() => {
                                setStartStreamingIndex(i);
                            }}
                        />
                    ))}
                </ul>

                {showPreprompts ? (
                    <Preprompts />
                ) : (
                    <Feedback customTrackingData={getCustomTrackingData()} />
                )}

                <div className="ai-disclaimer ai-disclaimer-text">
                    Journalistikken vår siden ditt forrige besøk er
                    oppsummert med kunstig intelligens, basert
                    på innhold som alltid er vurdert av VGs redaksjon.&nbsp;
                    <a href="https://www.vg.no/informasjon/redaksjonelle-avgjorelser/i14nmw?utm_campaign=skjeddsidensist" target="_blank">
                        Les mer om hvordan det fungerer her</a>, og gi oss gjerne
                    tilbakemelding hvis du oppdager noe som er feil!
                </div>
            </div>

            {user ? (
                <button
                    className="snakkis-show-more"
                    onClick={toggleOpen}
                    onPointerUp={() => null}
                >
                    {opened ? (
                        <>
                            Vis mindre
                            <ChevronUp />
                        </>
                    ) : (
                        <>
                            Vis mer
                            <ChevronDown />
                        </>
                    )}
                </button>
            ) : (
                <button
                    className="snakkis-login"
                    onClick={login}
                    onPointerUp={() => null}
                >
                    <>
                        Logg inn (gratis)
                        <ChevronDown />
                    </>
                </button>
            )}
        </section>
    );

    async function login() {
        // The login state (specifically "origin") is used to redirect the
        // user back to the same page after login
        const makeLoginState = () => {
            const state = `latest-news-chatbot-${Math.random().toString(16)}`;
            const origin = `https://www.vg.no/?debug_days=1&utm_source=latest-news-chatbot`;

            window.sessionStorage.setItem(
                'auth_state',
                JSON.stringify({
                    action: 'login',
                    state,
                    origin,
                }),
            );
            return state;
        };

        // The login hint is used to enable automatic login for returning users
        const getLoginHint = async () => {
            if (!window.SPiD_Identity) return null;

            try {
                const context = await window.SPiD_Identity.getUserContextData();
                return context?.identifier;
            } catch (error) {
                return null;
            }
        };

        const state = makeLoginState();
        const loginHint = await getLoginHint();

        window.SPiD_Identity.login({ state, loginHint });

        trackEngagement('Click', {
            contentId: 'login',
            id: 'login',
            custom: getCustomTrackingData(),
        });
    }

    async function getIdentity() {
        return new Promise((resolve) => {
            if (window.Identity) {
                resolve(window.Identity);
            } else {
                window.addEventListener('identity-initialized', () => {
                    resolve(window.Identity);
                });
            }
        });
    }
};

export default Snakkis;
